import { useState, useEffect } from 'react'
import { Image } from 'antd';
import ReactPlayer from 'react-player'


type MediaProps = {
    image:string
}

const Media: React.FC<MediaProps> = ({image}) => {
    
    const [isVideo, setIsVideo] = useState(false)

    let extension:any = image.split('.').pop()

    let extensionVideo = ['avi','mp4','mp3']

    useEffect(() => {
        setIsVideo(extensionVideo.includes(extension))  
    }, []);




    return(
        <>
            {
                isVideo ? 
                <ReactPlayer 
                    playing={true}
                    controls={true}
                    muted={true}
                    url={require(`/src/Utils/ChatImg/${image}`)} 
                    style={{padding: 10, maxWidth:'100%'}}
                    width="100%"

                />
                :
                <Image 
                    alt="Medart"
                    src={ image ? require(`/src/Utils/ChatImg/${image}`) : require('../Utils/Img/LOGO pour site internet.webp')}
                    preview={false}
                    style={{padding: 10}}
                />
            }
        </>
    )
} 

export default Media 