import React, {useState, useEffect, useRef} from 'react'
import CustomLayout from '../Components/CustomLayout';

import { Button, Dropdown, Menu, PageHeader, Table, Tag, message, Space, Input } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/es/table';


import Highlighter from 'react-highlight-words';


import axios, { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";

import DocumentModal from '../Components/DocumentPageComponents/DocumentModal'
import DocumentUpdateModal from '../Components/DocumentPageComponents/DocumentUpdateModal'
import TagDocumentCreationModal from '../Components/DocumentPageComponents/TagDocumentCreationModal';


interface IDocuments {
    key: React.Key;
    id_documents: number | React.Key
    nom_document: string 
    auteur: string
    description: string
    id_categorie: number | React.Key
    categorie: string
    couleur:string
    lien:string
    version: number
    date: Date
    modifDate: Date
    endDate: Date
}

type DataIndex = keyof IDocuments;



const Documents = () => {

    const [prenom, setPrenom] = useState('')
    const [nom, setNom] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)


    const refreshToken = async () => {
        try {
            const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
            const decoded:any = jwt_decode(response.data.accessToken);
            setPrenom(decoded.prenom)
            setNom(decoded.nom)
            setIsAdmin(decoded.isadmin);
        } catch (e:any) {
            console.log(e)
        }
    }
        
    useEffect(() => {
        refreshToken();
    }, []);



  
    const [select, setSelect] = useState({selectedRowKeys: [], selectedInfo: []});
    const [selectedDoc, setSelectedDoc] = useState(0)
    const [selectDocInfo, setSelectedDocInto] = useState({})

    const { selectedRowKeys, selectedInfo } = select;
    
    const rowSelection = {
        selectedRowKeys,
        selectedInfo,
        onChange: (selectedRowKeys: any, selectedInfo:any) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
                selectedInfo: selectedInfo
            });
            setSelectedDoc(selectedRowKeys[0])
            setSelectedDocInto(selectedInfo[0])  
        }
    };

    let lienSelected:IDocuments = select.selectedInfo[0] || ''
   
    
    const [documents, setDocuments] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isTagModalVisible, setIsTagModalVisible] = useState(false)
    
    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const showUpdateModal = () => {
        setIsUpdateModalVisible(true);
    };

    const showTagModal = () => {
        setIsTagModalVisible(true);
    };
    
    const getDocuments = async () => {
        try { 
            const response = await axios.get('https://intranet.med-art.fr/documents');
            setDocuments(response.data)
        }catch(e) {
            console.log(e)
        }
    }
    

    const delDocuments = async () => {
        let id_documents = selectedDoc
        
        try {
            await axios.delete(`https://intranet.med-art.fr/documents/${id_documents}`)
            .then(() => {
                message.success('Document supprimé avec succès', 10);
                window.location.reload()
            });
        }catch(e){
            console.log(e)
        }
    }


    // Gestion barre de recherche 

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IDocuments> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Rechercher`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 110 }}
              >
                Rechercher
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 110 }}
              >
                Réinitialiser
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText((selectedKeys as string[])[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filtrer
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
    });

    
    // --------------------------------------------------------------------------------------------------------------------------------

    const columns:any = [
        { title: 'N° du document', dataIndex:'id_documents', key:'id_documents',...getColumnSearchProps('id_documents')},
        { title: 'Titre du document', dataIndex: 'nom_document', key: 'nom_document',...getColumnSearchProps('nom_document'),},
        { title: 'Auteur', dataIndex: 'auteur', key: 'auteur',...getColumnSearchProps('auteur'),},
        { title: 'Catégorie', dataIndex: 'categorie', key: 'categorie',...getColumnSearchProps('categorie'),},
        { title: 'Version du document', dataIndex: 'version', key: 'version',},
        { title: 'Date de création', dataIndex: 'date', key: 'date', sorter: (a:any,b:any) => a.date.localeCompare(b.date),},
        { title: 'Date de modification', dataIndex: 'modifDate', key: 'modifDate', sorter: (a:any,b:any) => a.modifDate.localeCompare(b.modifDate),},
        { title: "Date d'archivage", dataIndex: 'endDate', key: 'endDate', sorter: (a:any,b:any) => a.modifDate.localeCompare(b.modifDate),}
    ];


    const endDateVerif = (endDate:Date) => {
        
        let infiniteDate = new Date(2100, 0, 1)
        let actualDate = new Date(endDate)

        if( actualDate < infiniteDate) {
            return true
        } else {
            return false
        }
    }
    

    const dataSource = documents.map((document:IDocuments) => (
        {
            key: document.id_documents,
            id_documents: document.id_documents,
            nom_document: document.nom_document/*(<a href={ require(`/src/Utils/Doc/${document.lien}`)} download>{document.nom_document}</a>)*/,
            auteur: document.auteur,
            description: document.description,
            couleur: document.couleur,
            categorie: (<Tag color={document.couleur}>{document.categorie}</Tag>),
            version: 'v.' + document.version,
            lien: document.lien,
            date: new Date(document.date).toLocaleDateString("fr"),
            modifDate: new Date(document.modifDate).toLocaleDateString("fr"),
            endDate: endDateVerif(document.endDate) ? new Date(document.endDate).toLocaleDateString("fr") : "Pas d'archivage",
        }
    ))

    const menu = (
        <Menu
            items={ isAdmin ? [
                {
                    label:(lienSelected.lien ? <a href={ require(`/src/Utils/Doc/${lienSelected.lien}`)} download >Télécharger un document</a> : 'Télécharger un document'),
                    key:'download',
                },
                {
                    label:'Ajouter un nouveau document',
                    key:'create',
                    onClick: (showModal)
                },
                {
                    label:'Modifier un document',
                    key:'modify',
                    onClick: (showUpdateModal)
                },
                {
                    label:'Supprimer un document',
                    key:'delete',
                    onClick: (delDocuments)
                },
                {
                    label:'Gestion des catégories',
                    key:'categorie',
                    onClick: (showTagModal)
                    
                },
            ] : [
                {
                    label:(lienSelected.lien ? <a href={ require(`/src/Utils/Doc/${lienSelected.lien}`)} download >Télécharger un document</a> : 'Télécharger un document'),
                    key:'download',
                },
                ]}
        />
    )


    useEffect (() => {
        getDocuments();
    }, [])


    return (
            <CustomLayout>
                <PageHeader 
                    title="Base documentaire"
                    breadcrumb={{
                        routes: [
                            { path: '/', breadcrumbName: 'Accueil'},
                            { path: `/Base documentaire`, breadcrumbName: 'Documents' },
                        ],
                    }}
                    extra={[
                        <Dropdown overlay={menu}>
                            <Button>
                                Actions
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    ]}
                />
                
                <Table
                    rowSelection={{
                        type: 'radio',
                        ...rowSelection
                    }}
                    dataSource={dataSource} 
                    columns={columns} 
                    expandable={{
                        expandedRowRender: document => <p>{document.description}</p>
                    }}
                    scroll={{ y: '100%' }}
                    rowKey={"id_documents"}
                />

                <DocumentModal visible={isModalVisible} setVisible={setIsModalVisible} nom={nom} prenom={prenom}/>
                <DocumentUpdateModal 
                    selectedDoc={selectedDoc} 
                    visible={isUpdateModalVisible} 
                    setVisible={setIsUpdateModalVisible} 
                    selectDocInfo={selectDocInfo}
                />
                <TagDocumentCreationModal visible={isTagModalVisible} setVisible={setIsTagModalVisible}/>
                
            </CustomLayout>
    )
}

export default Documents
