import {useState} from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios';

type ModalUpdateChatProps = {
    visible:boolean
    setVisible: any
    id_discussion:number
    oldTitle:string
    oldDiscussion:string
}

const ModalUpdateChat: React.FC<ModalUpdateChatProps> = ({id_discussion, oldTitle, oldDiscussion, visible, setVisible}) => {

    const handleCancel = () => {setVisible(false)};
    const [discussion, setNewDiscussion] = useState(oldDiscussion);
    const [title, setNewTitle] = useState(oldTitle);

    async function handleOk () {
        
        const updateChat = {
            discussion,
            title
        };
        
        try {
            await axios.put(`https://intranet.med-art.fr/discussion/${id_discussion}`, updateChat)
            .then(() => {
                message.success('Publication modifiée', 10);
                setVisible(false);
                window.location.reload();
                console.log(updateChat)
            });
        }catch(e){
            console.log(e)
            message.error("Echec lors de la modification du publication", 10);
        }
    }

    return (
        <Modal 
            title={`Modifier votre publication`}
            visible={visible}
            onCancel={handleCancel}
            footer={null}
        >
                <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de commenter' }]}>
                        <Input defaultValue={oldTitle} onChange={(e) => setNewTitle(e.target.value)} />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: 'Merci de commenter' }]}>
                        <TextArea rows={4} defaultValue={oldDiscussion} onChange={(e) => setNewDiscussion(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>
        </Modal>
    )
}

export default ModalUpdateChat