import { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Layout, Image, Input, Form, Button, message, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout'
import Title from 'antd/lib/typography/Title';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import image from '../Utils/Img/LOGO pour site internet.webp'



const Login = () => {
  
    const [email, setEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [msg, setMsg] = useState('');
    let navigate = useNavigate();
            
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        try{
            await axios.post('https://intranet.med-art.fr/login', {
                email: email,
                userPassword: userPassword
            }).then(() => {
                navigate('/', { replace: true })
                window.location.reload();
            });
        }catch(e:any){
            if (e.response) {
                setMsg(e.response.data.msg);
                message.error("Mauvais mot de passe")
            }
        }
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, height: 'auto', width: '100%', background: '#E57373', paddingBottom: 20 }}>
                <Image src={image} alt="logo_Medart" style={{paddingLeft: 20, paddingTop: 20, height: 100}} preview={false}/>        
            </Header>

            <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'50px', minHeight:'100vh'}}>
                <Title>
                    Bienvenue sur l'Intranet Med'Art
                </Title>
                <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                style={{marginTop: 50}}
                >
                    <Form.Item name="username" rules={[{ required: true, message: 'Merci de renseigner votre email !' }]} >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={e => setEmail(e.target.value)} />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: 'Merci de renseigner votre mot de passe !' }]} >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Mot de Passe" onChange={e => setUserPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType='submit' className="login-form-button" shape="round" style={{marginRight: 20, background: '#E57373', borderColor:'#1fe0'}}>
                            Se connecter
                        </Button>
                        Ou <a href="/register">S'inscrire</a>

                    </Form.Item>
                    <Space />

                    <a className="login-form-forgot" href="/mdpoublie">
                    Mot de passe oublié ?
                    </a>
                </Form>
            </Layout>
        </Layout>   
    )
}

export default Login

