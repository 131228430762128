import { FunctionComponent, ReactNode, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";
import { Layout, Menu, Image, PageHeader, Button, Affix, Divider } from 'antd'
import Title from 'antd/lib/typography/Title';
import { Content} from 'antd/lib/layout/layout'
import type { MenuProps } from 'antd';

import '../Utils/Style/Home.module.css';

import image from '../Utils/Img/LOGO pour site internet.webp'

const { Footer } = Layout;

interface PropsType {
    children?: ReactNode;
}

type MenuItem = Required<MenuProps>['items'][number];


const CustomLayout: FunctionComponent<PropsType> = (props) => {

    
    const [prenom, setPrenom] = useState('')
    const [expire, setExpire] = useState(0);
    const [isadmin, setIsAdmin] = useState(false)
    const [isMedart, setIsMedart] = useState(false)
    let navigate = useNavigate();

    
    useEffect(() => {
        refreshToken();
    }, );
    
    const refreshToken = async () => {
        try {
            const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
            const decoded:any = jwt_decode(response.data.accessToken);
            setPrenom(decoded.prenom);
            setExpire(decoded.exp);
            setIsAdmin(decoded.isadmin)
            setIsMedart(decoded.isMedart)

        } catch (e:any) {
            if (e.response) {
                navigate('/login', { replace: true })
            }
        }
    }
    
    const axiosJWT = axios.create();
    
    axiosJWT.interceptors.request.use(async (config:any) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://intranet.med-art.fr/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            const decoded:any = jwt_decode(response.data.accessToken);
            setPrenom(decoded.prenom);
            setExpire(decoded.exp);
            setIsAdmin(decoded.isadmin)
            setIsMedart(decoded.isMedart)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

        
    const Logout = async () => {
        try {
            await axios.delete('https://intranet.med-art.fr/logout');
            navigate('/login', { replace: true })
        } catch (error) {
            console.log(error);
        }
    }

    const subItems:MenuItem[] = isMedart ? 
        [
            {label: (<Link key='History' to='/Histoire'>Notre Histoire</Link>), key:'History'},
            {label: (<Link key='News' to='/Actualite'>Actualité</Link>), key:'News'},
            {label: (<Link key='Documents' to='/Documentation'>Base documentaire</Link>), key:'Documents'},
        ] : [
            {label: (<Link key='History' to='/Histoire'>Notre Histoire</Link>), key:'History'},
        ]
    
    const items: MenuItem[] =[
        {label: 'Entreprise',
            key: 'entreprise',
            children: subItems},
        {label: (<Link key='Chat' to='/Discussion'>Conversation</Link>), key:'Chat'},
        {label: (isMedart ?<Link key='CSE' to='/EspaceCSE'>Espace CSE</Link> : null), key:'CSE'},
        {label: (isadmin ? <Link key='Admin' to='/Admin'>Admin</Link> : null), key:'Admin'},

    ]

    const [collapsed,] = useState(false);



    return(
        <Layout style={{minHeight:"100vh"}}>
            <Affix offsetTop={0}>
                <PageHeader
                    title={<Image src={image} alt="logo_Medart" style={{paddingLeft: 10, paddingTop: 10, height: 90}} preview={false}/>}
                    extra={[
                        <Title level={4} style={{color:'#DA3F56', marginTop:7}}>Bonjour {prenom}</Title>,
                        <Button onClick={Logout} style={{background: '#1fe0', color:'#DA3F56'}}>Se déconnecter</Button>
                    ]}
                    style={{backgroundColor: '#FFFFFF', paddingBottom: 10}}
                    footer={
                        <>
                            <Menu mode="horizontal" style={{background: '#1fe0', display: 'flex', justifyContent: 'center', color:'#DA3F56', borderBottomColor: '#DA3F56'}}  inlineCollapsed={collapsed} items={items} /> 
                        </>
                    }
                >
                </PageHeader>
            </Affix>            
            <Layout style={{backgroundColor:'#FFFFFF'}}>
                <Content style={{padding: '0 50px', marginTop: 10, paddingBottom:10, backgroundColor:'#FFFFFF'}} >
                        {props.children}
                </Content>
            </Layout>
            <Footer style={{ textAlign: 'center', backgroundColor:'#FFFFFF', minWidth:'100%', color:'#DA3F56', borderColor:'#DA3F56' }}>
                <Divider style={{backgroundColor:'#DA3F56'}}/>
                    Il n'est pas simple de créer/conserver un lien dans ces conditions, mais c'est <strong>l'objectif</strong> que nous nous fixons. 
                    Alimenter un lien avec <strong>chacun d'entre vous</strong>, et 
                    renforcer la <strong>communication</strong> et <strong>l'esprit d'équipe</strong>. 
            </Footer>
        </Layout>
    )
}

export default CustomLayout
