import {useState} from 'react'
import { Button, Form, message, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'



import axios from 'axios';

interface Idata {
    comment:string
    userId: number
    id_discussion:number
}

type ModalCommentProps = {
    visible:boolean
    setVisible: any
    data:any
}




const ModalComment: React.FC<ModalCommentProps> = ({ data, visible, setVisible}) => {
    
    
    const handleCancel = () => {setVisible(false)};
    const [comment, setComment] = useState('');
    const userId = data.userId
    
    
    
    
    async function handleOk () {
        
        const postComment = {
            comment,
            userId
        };
        
        try {
            await axios.post(`https://intranet.med-art.fr/comment/${data.id_discussion}`, postComment)
            .then(() => {
                message.success('Nouveau commentaire ajouté', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
            message.error("Echec lors de l'ajout du commentaire", 10);
        }
    }

    
    return (
        <Modal 
        title={`Ajouter un commentaire à la publication : ${data.title} `}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        >
                <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de commenter' }]}>
                        
                        <TextArea rows={4} placeholder="Ajouter un commentaire" onChange={(e) => setComment(e.target.value)}/>
                        

                    </Form.Item>
                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>
        </Modal>
    )
}

export default ModalComment