import { useState } from 'react'
import { Modal, Button, Form, Upload, Input, message, DatePicker } from "antd"
import { UploadOutlined } from '@ant-design/icons';

import axios from 'axios';

import TagDocument from "../DocumentPageComponents/TagDocument";

import type { DatePickerProps } from 'antd';

import moment from 'moment';

import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

type ModalProps = {
    visible:boolean
    setVisible: any
    nom: string,
    prenom: string
}

interface ICategorie {
    id_categorie: number
    categorie: string,
    couleur: string,
}


const CseModal: React.FC<ModalProps> = ({visible, setVisible, nom, prenom}) => {

    
    const handleCancel = () => {setVisible(false)};
    
    const [nom_cse, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [lien, setLien] = useState('');
    const [categorie, setCategorie] = useState<ICategorie[]>([])
    const [selectedCategorie, setSelectedCategorie] = useState<ICategorie[]>([])
    


    async function handleOk (e: { preventDefault: () => void; }) {

        let categorie = selectedCategorie[0].id_categorie


        const postCse = {
            nom_cse,
            description,
            lien,
            categorie,
            endDate
        };

        try {
            await axios.post(`https://intranet.med-art.fr/cse`, postCse)
            .then(() => {
                message.success('Nouveau document ajouté', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
        }
    }

    const onUpload = (value: any) => {
        const cseName = value.file.name
        setLien(cseName)
    }

    let dateDefault:string = moment().add(6, 'month').format()

    const [endDate, setEndDate] = useState(dateDefault)


    const onChangeEndDate: DatePickerProps['onChange'] = (date, enddateString) => {
        setEndDate(enddateString);
    };


    return (
        <>
            <Modal 
                title="Ajouter un nouveau document" 
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                >
                <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}>
                        <Input placeholder="Ajouter un titre" onChange={(e) => setNom(e.target.value)}/>
                    </Form.Item>

                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre description !' }]}>
                        <Input placeholder="Ajouter une description" onChange={(e) => setDescription(e.target.value)}/>
                    </Form.Item>
                    
                    <Form.Item name="cse" rules={[{ required: true, message: 'Merci de sélectionner un document !' }]}>
                        <Upload name="cse" action={'https://intranet.med-art.fr/CseUpload'} onChange={onUpload}>
                            <Button icon={<UploadOutlined />}>Charger un document</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="endDate" label="Date d'Archivage" >
                        <DatePicker locale={locale} defaultValue={moment().add(6, 'month')} onChange={onChangeEndDate} />
                    </Form.Item>

                    <TagDocument categorie={categorie} setCategorie={setCategorie} selectedCategorie={selectedCategorie} setSelectedCategorie={setSelectedCategorie}/>


                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}

export default CseModal