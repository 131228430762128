import { useNavigate } from 'react-router-dom'
import CustomLayout from '../Components/CustomLayout'
import {Button, Layout} from 'antd'

import Title from 'antd/lib/typography/Title';

const Error = () => {

    let navigate = useNavigate();

    const handleClick = () => {
        navigate('/', { replace: true })
    }

    return (
        <CustomLayout>
            <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'200px'}}>
                <Title>Oups ! Cette page n'existe pas</Title>
                <Button type="primary" shape="round" onClick={handleClick} style={{background: '#E57373', borderColor:'#1fe0'}}>Revenir à l'accueil</Button>
            </Layout>
        </CustomLayout>

    )
}

export default Error