import {useState, useEffect} from 'react'

import axios from 'axios';

import { Tag, Form, Button } from 'antd';


interface ICategorie {
    id_categorie: number
    categorie: string,
    couleur: string,
}

type catProps = {
    categorie: ICategorie[]
    setCategorie: any
    selectedCategorie: ICategorie[]
    setSelectedCategorie: any
}

const TagDocument: React.FC<catProps> = ({categorie, setCategorie, selectedCategorie, setSelectedCategorie}) => {

    



    const [selectedTag, setSelectedTag] = useState('')

    

    const getCategories = async () => {
        try { 
            const response = await axios.get('https://intranet.med-art.fr/categorie');
            setCategorie(response.data)

        }catch(e) {
            console.log(e)
        }
    }
    

    const getCategorieById = async () => {
        try { 
            const response = await axios.get(`https://intranet.med-art.fr/categorie/${selectedTag}`);
            setSelectedCategorie(response.data)
    
        }catch(e) {
            console.log(e)
        }
    }


    
    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        
        const button: HTMLButtonElement = event.currentTarget;
        setSelectedTag(button.value);
        
    };

    
    useEffect (() => {
        getCategories();
        getCategorieById()
    }, [getCategorieById, setSelectedCategorie])

    return(
        <>
            { selectedTag ? 
                <>
                    Catégorie séléctionnée : 
                    <Tag color={selectedCategorie[0].couleur}>{selectedCategorie[0].categorie}</Tag>
                </>
            : null
            }



            <Form.Item>
                {categorie.map(({id_categorie, categorie, couleur}:ICategorie) => (
                    <Tag key={id_categorie} color={couleur}>
                        <Button value={id_categorie} ghost size={'small'} style={{borderColor:'#1fe0'}} onClick={handleClick}>
                            {categorie}
                        </Button>
                    </Tag>
                ))}
            </Form.Item>
        </>
    )
}

export default TagDocument

