import { useState, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios';

type LikeCounterProps = {
    id_discussion:number
}


const LikeCounter: React.FC<LikeCounterProps> = ({id_discussion}) => {

    const [likeNumber, setLikeNumber] = useState(0)

    useEffect(() => {
        getLikeCount()
    }, []);

    async function getLikeCount() {
        const response:AxiosResponse = await axios.get(`https://intranet.med-art.fr/like/${id_discussion}`)
        setLikeNumber(response.data[0].count)        
    }

    const LikeDisplay = () => {
        if(likeNumber == 1){
            return <>{likeNumber} personne a aimé </>
        }else if(likeNumber > 1){
            return <>{likeNumber} personnes ont aimé </>
        }    
        else{
            return <>Soyez le premier à aimer</>
        }
    }


    return (
        <LikeDisplay />
    )
}

export default LikeCounter 