import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './Utils/Test/reportWebVitals';
import { AppRouter } from './Routes/index';
import axios from "axios";
import './Utils/Style/globals.css';

import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';


axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ConfigProvider locale={frFR}>
      <AppRouter />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
