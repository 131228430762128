import { useState, useEffect } from 'react'
import axios from 'axios';
import { Comment, List, message } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


import ModalUpdateComment from './ModalUpdateComment';

type CommentSectionProps = {
    id_discussion:number,
    id_user:number
}


interface IComments {
    id_comment:number,
    comment: string, 
    date_comment: Date,
    prenom:string
    id_users:number
    isAuthor:boolean
}


const CommentSection: React.FC<CommentSectionProps> = ({id_discussion, id_user}) => {

    const [comments, setComment] = useState<IComments[]>([])



    const getComment = async () => {
        try {
            const response = await axios.get(`https://intranet.med-art.fr/comment/${id_discussion}`);
            setComment(response.data)
        }catch(e) {
            console.log(e)
        }
    }

    const deleteComment = async (id_comment:number) => {
        try {
            await axios.delete(`https://intranet.med-art.fr/comment/${id_comment}`)
            .then(() => {
                message.success('Commentaire supprimé avec succès', 10);
                window.location.reload()
            });
        }catch(e){
            console.log(e)
        }
    }

    const [uploadedCommentNumber , setUploadedCommentNumber] = useState(0)
    const [uploadedComment , setUploadedComment] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    
    
    const showModal = (id_comment: number, comment:string, e: any) => {
        e.preventDefault();
        setIsModalVisible(!isModalVisible);
        setUploadedCommentNumber(id_comment)
        setUploadedComment(comment)
    };

    useEffect(() => {
        getComment();
    }, []);

    const commentData = comments.sort((a, b) => new Date(a.date_comment).getTime() - new Date(b.date_comment).getTime()).map((comment:IComments) => (
        {
            id_comment:comment.id_comment,
            author: comment.prenom,
            avatar: 'https://joeschmoe.io/api/v1/random',
            comment: comment.comment,
            datetime: comment.date_comment,
            isAuthor: id_user === comment.id_users
        }
    ))


    return (
        <> { commentData.length > 0 ?
            <List
                className="comment-list"
                header={`${commentData.length} réponses`}
                itemLayout="horizontal"
                dataSource={commentData}
                style={{backgroundColor:'white', minWidth:'45%', padding: 5}}
                bordered={true}
                locale={{emptyText:'Laisser un commentaire'}}
                renderItem={item => (item.isAuthor ?
                    <li style={{backgroundColor:"#D9F6FB"}}>
                    <Comment
                        actions={item.isAuthor ? [
                            <EditOutlined key="edit" onClick={(e) => showModal(item.id_comment, item.comment, e)}/>,
                            <DeleteOutlined key="delete" onClick={()=>deleteComment(item.id_comment)}/>,
                        ]:[null]}
                        author={item.author}
                        avatar={item.avatar}
                        content={item.comment}
                        datetime={<>Publié le {new Date(item.datetime).toLocaleDateString("fr")}</>}
                        />
                </li>
                :
                <li>
                    <Comment
                        actions={item.isAuthor ? [
                            <EditOutlined key="edit" onClick={(e) => showModal(item.id_comment, item.comment, e)}/>,
                            <DeleteOutlined key="delete" onClick={()=>deleteComment(item.id_comment)}/>,
                        ]:[null]}
                        author={item.author}
                        avatar={item.avatar}
                        content={item.comment}
                        datetime={<>Publié le {new Date(item.datetime).toLocaleDateString("fr")}</>}
                        />
                </li>
                )}
                />
                : null
            }
            <ModalUpdateComment id_comment={uploadedCommentNumber} oldComment={uploadedComment} visible={isModalVisible} setVisible={setIsModalVisible}/>
        </>
    )
}

export default CommentSection


