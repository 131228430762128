import { useState,useEffect } from 'react'
import CustomLayout from '../Components/CustomLayout';
import { Layout, Affix, PageHeader, Button } from 'antd';

import ModalChat from '../Components/ModalChat';
import DiscussionCard from '../Components/DiscussionCard'

import axios, { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";


const Discussion = () => {
  
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [prenom, setPrenom] = useState('')
    const [userId, setUserId] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
        
    const showModal = () => {
        setIsModalVisible(true);
    };

    const refreshToken = async () => {
        try {
            const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
            const decoded:any = jwt_decode(response.data.accessToken);
            setPrenom(decoded.prenom)
            setUserId(decoded.userId)
            setIsAdmin(decoded.isadmin);
        } catch (e:any) {
            console.log(e)
        }
    }
        
    useEffect(() => {
        refreshToken();
    }, []);

    return (
        <CustomLayout>
            <Affix offsetTop={210}>
                <PageHeader 
                    title="Conversation"
                    breadcrumb={{
                        routes: [
                            { path: '/', breadcrumbName: 'Accueil'},
                            { path: '/Conversation', breadcrumbName: 'Conversation' },
                    ],
                    }}
                    extra={[
                        <Button onClick={showModal}> Créer une nouvelle publication</Button>

                    ]}
                >
                </PageHeader>
            </Affix>
                <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'10px', backgroundColor:'#FFFFFF'}}>

                    <DiscussionCard userId={userId} isAdmin={isAdmin}/>

                    <ModalChat userId={userId} visible={isModalVisible} setVisible={setIsModalVisible}/>
                </Layout>
        </CustomLayout>
    )
}

export default Discussion
