import { useState } from 'react'

import { Button, Form, Input, message, Modal, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

type ModalProps = {
    visible:boolean
    setVisible: any
    userId:number
}



const ModalChat: React.FC<ModalProps> = ({userId, visible, setVisible}) => {
    
    const handleCancel = () => {setVisible(false)};
    const [title, setTitle] = useState('');
    const [discussion, setDiscussion] = useState('');
    const [image, setImage] = useState('')


    
    async function handleOk (e: { preventDefault: () => void; }) {

        const postDiscussion = {
            title,
            discussion,
            image,
        };

        try {
            await axios.post(`https://intranet.med-art.fr/discussion/${userId}`, postDiscussion)
            .then(() => {
                message.success('Nouvelle discussion ajoutée', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
        }
    }

    const onUpload = (value: any) => {
        const imageName = value.file.name
        console.log(imageName)
        setImage(imageName)
    }



    return(
        <>
            <Modal 
                title="Créer une nouvelle publication" 
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                >
                    <Form
                        onFinish={handleOk}
                    >
                        <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}>
                            <Input placeholder="Ajouter un titre" onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre texte !' }]}>
                            <TextArea rows={4} placeholder="Taper votre publication" onChange={(e) => setDiscussion(e.target.value)}/>
                        </Form.Item>
                        <Form.Item name="image">
                            <Upload name="image" action={'https://intranet.med-art.fr/ChatImage'} onChange={onUpload}>
                                <Button icon={<UploadOutlined />}>Charger une image</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                                Valider
                            </Button>
                            <Button key="cancel" onClick={handleCancel}>
                                Annuler
                            </Button>
                        </Form.Item>
                    </Form>
            </Modal>
        </>
    )
}

export default ModalChat