import {useState} from 'react'
import { Button, Form, message, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios';

type ModalUpdateCommentProps = {
    visible:boolean
    setVisible: any
    id_comment:number
    oldComment:string
}

const ModalUpdateComment: React.FC<ModalUpdateCommentProps> = ({ id_comment, oldComment, visible, setVisible}) => {

    const handleCancel = () => {setVisible(false)};
    const [comment, setNewComment] = useState(oldComment);

    async function handleOk () {
        
        const updateComment = {
            comment,
        };
        
        try {
            await axios.put(`https://intranet.med-art.fr/comment/${id_comment}`, updateComment)
            .then(() => {
                message.success('Commentaire modifié', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
            message.error("Echec lors de la modification du commentaire", 10);
        }
    }

    return (
        <Modal 
            title={`Modifier votre commentaire`}
            visible={visible}
            onCancel={handleCancel}
            footer={null}
        >
                <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de commenter' }]}>
                        
                        <TextArea rows={4} defaultValue={oldComment} onChange={(e) => setNewComment(e.target.value)} />
                        

                    </Form.Item>
                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>
        </Modal>
    )
}

export default ModalUpdateComment