import React, {useState, useEffect} from 'react'

import axios from 'axios';

import { Modal, Form, Input, Button, Divider, Typography, message, Tag } from 'antd';

import { AnyColorFormat, Colorpicker } from 'antd-colorpicker'


const { Title } = Typography;

type ModalProps = {
    visible:boolean
    setVisible: any
}

interface ICategorie {
    id_categorie: number
    categorie: string,
    couleur: string,
}



const TagDocumentCreationModal: React.FC<ModalProps> = ({visible, setVisible}) => {

    const [categorie, setCategorie] = useState('');
    const [couleur, setCouleur] = useState<AnyColorFormat>({"hex": "#4f4a67"});

    const onChange = (couleur: AnyColorFormat) => {
        setCouleur(couleur.hex)
    }


    const handleCancel = () => {setVisible(false)};


    async function handleOk (e: { preventDefault: () => void; }) {

        const postCategories = { categorie, couleur };

        try {
            await axios.post(`https://intranet.med-art.fr/categorie`, postCategories)
            .then(() => {
                message.success('Nouvelle catégorie ajouté', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
        }
    }

    const [categories, setCategories] = useState<ICategorie[]>([])

    const getCategories = async () => {
        try { 
            const response = await axios.get('https://intranet.med-art.fr/categorie');
            setCategories(response.data)
            
        }catch(e) {
            console.log(e)
        }
    }

    const handleClose = async (catId:number) => {
        try { 
            await axios.delete(`https://intranet.med-art.fr/categorie/${catId}`)
            .then(() => {
                message.success('Catégorie supprimée avec succès', 10);
            });
        }catch(e){
            console.log(e)
        }


    }

    useEffect (() => {
        getCategories();
    }, [])



    return(
        <Modal
        title="Gestion des catégories" 
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        >
            <Title level={5}>
                Catégorie existante (cliquer sur la croix pour supprimer):
            </Title>
            {categories.map(({id_categorie, categorie, couleur}:ICategorie) => (
                <Tag key={id_categorie} color={couleur} closable onClose={() => handleClose(id_categorie)}>        
                    {categorie}
                </Tag>
            ))}


            <Divider />

            <Form
                onFinish={handleOk}
            >
                <Title level={5}>
                    Créer une nouvelle catégorie :
                </Title>
        
                <Form.Item /*rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}*/>
                    <Input placeholder="Ajouter un titre à la catégorie" onChange={(e) => setCategorie(e.target.value)} />
                </Form.Item>

                <Form.Item label={'Sélectionner une couleur'} name={`color`}>
                    <Colorpicker popup value={couleur} onChange={onChange}/>
                </Form.Item>
                

                <Form.Item>
                    <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                        Valider
                    </Button>
                    <Button key="cancel" onClick={handleCancel}>
                        Annuler
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default TagDocumentCreationModal