import {useState,useEffect} from 'react'
import axios, { AxiosResponse } from 'axios';

import { LikeOutlined, LikeTwoTone } from '@ant-design/icons';

type LikeCounterProps = {
    id_discussion:number
    userId:number

}





const Like: React.FC<LikeCounterProps> = ({id_discussion, userId}) => {

    const [isLiked, setIsLiked] = useState(false)

    useEffect(() => {
        verifyLike()
    }, []);

    async function verifyLike() {
        const response:AxiosResponse = await axios.get(`https://intranet.med-art.fr/like/${id_discussion}&${userId}`)
        setIsLiked(response.data)        
    }


    async function discussionLike() {
        if(isLiked === false){
            const response:AxiosResponse = await axios.post(`https://intranet.med-art.fr/like/${id_discussion}&${userId}`)
            setIsLiked(response.data)
            window.location.reload()
        }else {
            const response:AxiosResponse = await axios.delete(`https://intranet.med-art.fr/like/${id_discussion}&${userId}`)
            setIsLiked(response.data)
            window.location.reload()
        }

    }


    return (
        <>
            {isLiked ? <LikeTwoTone onClick={discussionLike} /> : <LikeOutlined onClick={discussionLike} />}
        </>
    )
}

export default Like 
