import {useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";

import Login from '../Components/Login'
import Register from '../Components/Register'
import HomePage from '../Pages/Home'
import NewsPage from '../Pages/News'
import HistoirePage from '../Pages/Histoire'
import CSEPage from '../Pages/PageCSE'
import DocumentsPage from '../Pages/Documents'
import DiscussionPage from '../Pages/Chat'
import AdminPage from '../Pages/Admin'
import ErrorPage from '../Pages/Erreur'
import MdpOubliePage from '../Components/mdpoublie'
import WaitingValidation from '../Pages/Waitingvalidation';
import RichTextEditor from '../Components/RichTextEditor';


export const AppRouter = () => {

    const [isadmin, setIsAdmin] = useState(false)
    const [waitingValidation, setValidate] = useState(true)
    const [isMedart, setIsMedart] = useState(false)



    const refreshToken = async () => {
        try {
            const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
            const decoded:any = jwt_decode(response.data.accessToken);
            setIsAdmin(decoded.isadmin)
            setValidate(decoded.iswaitingvalidation)
            setIsMedart(decoded.isMedart)

        } catch (e:any) {
            console.log(e)
        }
    }

    useEffect(() => {
        refreshToken();
    }, []);


    return(

        <BrowserRouter>
            <Routes>
                <Route path='/text' element={<RichTextEditor />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register/>} />
                <Route path='/mdpoublie' element={<MdpOubliePage/>} />

                { waitingValidation ? 
                    <>
                        <Route path="/" element={<WaitingValidation />} />
                    </>
                    : isMedart ?

                        isadmin ?
                            <>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/Actualite" element={<NewsPage />} />
                                <Route path="/Histoire" element={<HistoirePage />} />
                                <Route path="/Documentation" element={<DocumentsPage />} />
                                <Route path="/EspaceCSE" element={<CSEPage />} />
                                <Route path="/Discussion" element={<DiscussionPage />} />
                                <Route path="/Admin" element={<AdminPage />} />
                                <Route path="*" element={<ErrorPage />} />
                            </> 
                            : 
                            <>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/Actualite" element={<NewsPage />} />
                                <Route path="/Histoire" element={<HistoirePage />} />
                                <Route path="/Documentation" element={<DocumentsPage />} />
                                <Route path="/EspaceCSE" element={<CSEPage />} />
                                <Route path="/Discussion" element={<DiscussionPage />} />
                                <Route path="*" element={<ErrorPage />} />
                            </>   
                        :
                        <>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/Histoire" element={<HistoirePage />} />
                            <Route path="/Discussion" element={<DiscussionPage />} />
                            <Route path="*" element={<ErrorPage />} />
                        </>
                }
            </Routes>
        </BrowserRouter>
    )
}

