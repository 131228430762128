import { useState, useEffect } from 'react'
import { Modal, Button, Form, Upload, Input, message } from "antd"
import { UploadOutlined } from '@ant-design/icons';

import axios from 'axios';

import TagDocument from "../DocumentPageComponents/TagDocument";

type ModalProps = {
    visible:boolean
    setVisible: any
    selectedDoc: number
    selectDocInfo: any
}

type selectDocInfo = {
    nom_cse: string
    description: string
    lien: string
    version: string
}

interface ICategorie {
    id_categorie: number
    categorie: string,
    couleur: string,
}


const CseUpdateModal: React.FC<ModalProps> = ({selectedDoc, visible, setVisible, selectDocInfo}) => {

    const handleCancel = () => {setVisible(false)};

    let selected:selectDocInfo = selectDocInfo
   
    
    const [nom_cse, setNom] = useState(selected.nom_cse);
    const [description, setDescription] = useState(selected.description);
    const [lien, setLien] = useState(selected.lien);
    const [version, setVersion] = useState(1)
    
    const [categorie, setCategorie] = useState<ICategorie[]>([])
    const [selectedCategorie, setSelectedCategorie] = useState<ICategorie[]>([])
    
    
    
    async function handleOk (e: { preventDefault: () => void; }) {

        let categorie = selectedCategorie[0].id_categorie

        const putCse = {
            nom_cse,
            description,
            lien,
            version,
            categorie
        };

        try {
            await axios.put(`https://intranet.med-art.fr/cse/${selectedDoc}`, putCse)
            .then(() => {
                message.success('Document modifiée', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
        }
    }
    
    const onUpload = (value: any) => {
        const cseName = value.file.name
        setLien(cseName)
    }
    
        
    useEffect (() => {
        let versionString:string = selected.version || ''
    
    
        let versionArray:string[] = versionString?.split('.')
        let versionNumber:number = parseInt(versionArray[1])
        
        setVersion(versionNumber)
        
        
    }, [selected.version])



    return (
        <>
            <Modal 
                title={"Modifier le document numéro: " + selectedDoc}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                >
               <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}>
                        <Input defaultValue={selected.nom_cse} onChange={(e) => setNom(e.target.value)}/>
                    </Form.Item>

                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre description !' }]}>
                        <Input defaultValue={selected.description} onChange={(e) => setDescription(e.target.value)}/>
                    </Form.Item>

                    <Form.Item name="cse" >
                        <Upload name="cse" action={'https://intranet.med-art.fr/CseUpload'} onChange={onUpload}>
                            <Button icon={<UploadOutlined />}>Charger un document</Button>
                        </Upload>
                    </Form.Item>

                    <TagDocument categorie={categorie} setCategorie={setCategorie} selectedCategorie={selectedCategorie} setSelectedCategorie={setSelectedCategorie}/>
          
                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )

}

export default CseUpdateModal