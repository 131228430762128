import * as React from 'react'
import CustomLayout from '../Components/CustomLayout';
import Title from 'antd/lib/typography/Title';
import { Layout, Card, Col, Row } from 'antd';

import image1 from '../Utils/Img/row-of-young-adults-at-work.jpg'
import image2 from '../Utils/Img/woman-edits-images-on-her-laptop.jpg'

const Home = () => {
  
    return (
            <CustomLayout>
                <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'10px', backgroundColor:'#FFFFFF'}}>
                    <Title level={1}>
                        Bienvenue sur l'Intranet Med'Art
                    </Title>
                    <Title level={2}>
                        Mais pourquoi un intranet ?
                    </Title>

                    <Row gutter={[40, 16]}>
                        <Col span={12}>
                            <Card style={{ width: 500 }}
                            cover={<img alt="img1" src={image1} />}>
                                <Title level={5} style={{display: 'flex', justifyContent: 'center'}}>Raison 1 : Parce qu'on grandit</Title>
                            </Card>
                        </Col>
                    <Col span={12}>
                    <Card style={{ width: 500 }}
                    cover={<img alt="img2" src={image2} />}>
                        <Title level={5} style={{display: 'flex', justifyContent: 'center'}}>Raison n°2 : Parce qu'on est loin les uns des autres</Title>
                    </Card>
                    </Col>
                    </Row>

                </Layout>
            </CustomLayout>
    )
}

export default Home
