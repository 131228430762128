import { useNavigate } from 'react-router-dom'
import { Header } from 'antd/lib/layout/layout'
import { Layout, Image, Button } from 'antd';

import Title from 'antd/lib/typography/Title';

import image from '../Utils/Img/LOGO pour site internet.webp'

const WaitingValidation = () => {

    let navigate = useNavigate();

    const handleClick = () => {
        navigate('/login', { replace: true })
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, height: 'auto', width: '100%', background: '#E57373', paddingBottom: 20 }}>
                <Image src={image} alt="logo_Medart" style={{paddingLeft: 20, paddingTop: 20, height: 100}} preview={false}/>        
            </Header>

            <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'50px', minHeight:'100vh'}}>
                <Title level={1}>
                    Bienvenue sur l'Intranet Med'Art
                </Title>
                <Title level={5}>
                    Il semble que votre profil soit en attente de validation suite à votre inscription. 
                    Dès que votre administrateur aura validé votre profil vous pourrez vous y connecter. 
                </Title>
                <Button type="primary" shape="round" onClick={handleClick} style={{background: '#E57373', borderColor:'#1fe0'}}>Revenir à l'accueil</Button>
            </Layout>
        </Layout>   
    )
}

export default WaitingValidation