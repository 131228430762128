import { useState } from 'react'
import { Modal, Button, Form, Upload, Input, message, DatePicker, Checkbox } from "antd"
import { UploadOutlined } from '@ant-design/icons';

import axios from 'axios';

import TagDocument from "./TagDocument";

import type { DatePickerProps } from 'antd';

import moment from 'moment';

import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

type ModalProps = {
    visible:boolean
    setVisible: any
    nom: string,
    prenom: string
}

interface ICategorie {
    id_categorie: number
    categorie: string,
    couleur: string,
}


const DocumentModal: React.FC<ModalProps> = ({visible, setVisible, nom, prenom}) => {

    
    const handleCancel = () => {setVisible(false)};
    
    const [nom_document, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [lien, setLien] = useState('');
    const [categorie, setCategorie] = useState<ICategorie[]>([])
    const [selectedCategorie, setSelectedCategorie] = useState<ICategorie[]>([])

    const [infiniteEndDate, setInfiniteEndDate] = useState(true)
    
    let auteur = nom + ' ' + prenom


    

    async function handleOk (e: { preventDefault: () => void; }) {

        let categorie = selectedCategorie[0].id_categorie



        const postDocument = {
            nom_document,
            description,
            lien,
            categorie,
            auteur,
            endDate
        };


        try {
            await axios.post(`https://intranet.med-art.fr/documents`, postDocument)
            .then(() => {
                message.success('Nouveau document ajouté', 10);
                setVisible(false);
                window.location.reload();
            });
        }catch(e){
            console.log(e)
        }
    }

    const onUpload = (value: any) => {
        const documentName = value.file.name
        setLien(documentName)
    }

    
    let dateDefault:string = moment().add(6, 'month').format()
    let infiteDate:string = moment().add(100, 'years').format()

    const [endDate, setEndDate] = useState(infiteDate)
    
    
    const onChangeEndDate: DatePickerProps['onChange'] = (date, enddateString) => {
        setEndDate(enddateString);
        
    };
    
    const handleCheckbox = () => {
        setInfiniteEndDate(!infiniteEndDate)

        if(!infiniteEndDate === true) {
            setEndDate(infiteDate)
        } else {
            setEndDate(dateDefault);
        }
    }


    return (
        <>
            <Modal 
                title="Ajouter un nouveau document" 
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                >
                <Form
                    onFinish={handleOk}
                >
                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}>
                        <Input placeholder="Ajouter un titre" onChange={(e) => setNom(e.target.value)}/>
                    </Form.Item>

                    <Form.Item rules={[{ required: true, message: 'Merci de renseigner votre description !' }]}>
                        <Input placeholder="Ajouter une description" onChange={(e) => setDescription(e.target.value)}/>
                    </Form.Item>

                    <Form.Item name="document" rules={[{ required: true, message: 'Merci de sélectionner un document !' }]}>
                        <Upload name="document" action={'https://intranet.med-art.fr/DocumentUpload'} onChange={onUpload}>
                            <Button icon={<UploadOutlined />}>Charger un document</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="infinitEndDate" label="Date d'Archivage infini ?" >
                        <Checkbox checked={infiniteEndDate} onChange={handleCheckbox}/>
                    </Form.Item>

                    { !infiniteEndDate ? 
                        <Form.Item name="endDate" label="Date d'Archivage" >
                            <DatePicker locale={locale} defaultValue={moment().add(6, 'month')} onChange={onChangeEndDate} />
                        </Form.Item>
                        : null
                    }

                    <TagDocument categorie={categorie} setCategorie={setCategorie} selectedCategorie={selectedCategorie} setSelectedCategorie={setSelectedCategorie}/>

                    <Form.Item>
                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                            Valider
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Annuler
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}

export default DocumentModal