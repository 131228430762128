import { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Layout, Image, Input, Form, Button, message } from 'antd';
import { Header } from 'antd/lib/layout/layout'

import Title from 'antd/lib/typography/Title';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import image from '../Utils/Img/LOGO pour site internet.webp'





const ReinitMdp = ({email}:any) =>{

    const [userPassword, setUserPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    let navigate = useNavigate();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        try {
            await axios.put('https://intranet.med-art.fr/mdpoublie', {
                email: email,
                userPassword: userPassword,
                confPassword: confPassword,
            }).then(() => {
                navigate('/login', { replace: true })
                message.success('Mot de passe modifié, Merci de vous connecter', 10);
            });
        } catch(e) {
            console.log(e)
        }
    }


    return (
        <Form
        name="normal_login"
            className="register-form"
            style={{marginTop: 50}}
            onFinish={handleSubmit}
            layout="vertical"
        >
            <Form.Item name="userPassword" rules={[{ required: true, message: 'Merci de renseigner votre mot de passe !' }]} >
                <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Mot de Passe" type="password"  onChange={e => setUserPassword(e.target.value)}/>
            </Form.Item>
            <Form.Item name="confPassword" rules={[{ required: true, message: 'Merci de confirmer votre mot de passe !' }]} >
                <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirmation Mot de Passe" type="password" onChange={e => setConfPassword(e.target.value)}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType='submit' shape="round" style={{background: '#E57373', borderColor:'#1fe0'}}>Réinitialiser le mot de passe</Button>
            </Form.Item>
        </Form> 
    )
}



const Mdpoublie = () => {
    
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('');
    const [MailOK, setmailOK] = useState(false)
    const [user, setUSer] = useState(0)
    
    
    const emailSubmit = async (e: { preventDefault: () => void; }) => {
        try {
            await axios.post('https://intranet.med-art.fr/mdpoublie', {
                email: email,
            }).then(() => {
                setmailOK(true)
                
            })
        } catch(e:any) {
            if (e.response) {
                setMsg(e.response.data.msg);
                message.error(msg, 10)
                console.log('mauvais mail')
            }
        }
        return MailOK
    }
    
    
    
    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, height: 'auto', width: '100%', background: '#E57373', paddingBottom: 20 }}>
                <Image src={image} alt="logo_Medart" style={{paddingLeft: 20, paddingTop: 20, height: 100}} preview={false}/>        
            </Header>

            <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'50px', minHeight:'100vh'}}>

            <Form
                name="password-reinit"
                className="password-form"
                initialValues={{ remember: false }}
                onFinish={emailSubmit}
                >
                <Form.Item>
                    <Title level={4}>Tapez votre email</Title>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={e => setEmail(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType='submit' shape="round" style={{background: '#E57373', borderColor:'#1fe0', marginRight: 20}}>
                            Soumettre
                    </Button>
                    <a href="/login">Retour</a>
                </Form.Item>

            </Form>


            {MailOK ? <ReinitMdp email={email} /> : null }




            </Layout>

        </Layout>

    )
}

export default Mdpoublie