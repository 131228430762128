import { useState, useEffect, MouseEvent } from 'react'
import axios, { AxiosResponse } from 'axios';

import { Card, Typography, message } from 'antd';
import Like from './Like';
import LikeCounter from './LikeCounter';
import ModalComment from './ModalComment';

import { CommentOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CommentSection from './CommentSection';
import ModalUpdateChat from './ModalUpdateChat';

import Media from './Media';





const { Text } = Typography;

interface IDiscussion {
    [index:number] : number,
    id_discussion:number,
    id_users:number,
    title:string,
    discussion:string,
    image:string,
    like: number,
    date_discussion:Date,
    prenom:string,
    nom:string
}

type DiscussionCardProps = {
    userId:number
    isAdmin:boolean
}


const DiscussionCard: React.FC<DiscussionCardProps> = ({userId, isAdmin}) => {
    
    
    const [discussionData, setDiscussionData] = useState<IDiscussion[]>([]);
    
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false)
    
    const [selectedDiscussion, setSelectedDiscussion] = useState({id_discussion:0, title:'', discussion:'',image:'', userId:userId})
    

    async function getDiscussion() {
        const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/discussion')
        setDiscussionData(response.data)

  
    }


    const showModal = (id_discussion: number, title: string, discussion:string, image:string, userId:number,e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        setIsModalVisible(!isModalVisible);
        setSelectedDiscussion({id_discussion, title, discussion, image, userId})
    };


    useEffect(() => {
        getDiscussion()
    }, []);

    const handleDelete = async (id_discussion: number) => {
        try {
            await axios.delete(`https://intranet.med-art.fr/discussion/${id_discussion}`)
            .then(() => {
                message.success('Discussion supprimé avec succès', 10);
                window.location.reload()
            });
        }catch(e){
            console.log(e)
        }
    }

    const [uploadedDiscussionNumber, setUploadedDiscussionNumber] = useState(0)
    const [uploadedDiscussion, setUploadedDiscussion] = useState('')
    const [uploadedTitle, setUploadedTitle] = useState('')

    const ModifModal = (id_discussion: number, title: string, discussion: any, e: any) => {
        e.preventDefault();
        setIsModalUpdateVisible(!isModalUpdateVisible);
        setUploadedDiscussionNumber(id_discussion)
        setUploadedDiscussion(discussion)
        setUploadedTitle(title)
    }



    return(
        <>
            {discussionData.sort((a, b) => new Date(b.date_discussion).getTime() - new Date(a.date_discussion).getTime()).map(({id_discussion, id_users, title, discussion, image, date_discussion, prenom}:IDiscussion) => (
                <>
                    <Card
                        hoverable
                        key={id_discussion}
                        title={title}
                        extra={<>Publié le {new Date(date_discussion).toLocaleDateString("fr")} par {prenom}</>}
                        style={{ width: '50%', maxWidth: '50%', marginTop: 10 }}
                        actions={ isAdmin ?
                            [
                                <LikeCounter id_discussion={id_discussion}/>,
                                <Like id_discussion={id_discussion} userId={userId} />,
                                <CommentOutlined onClick={(e) => showModal(id_discussion, title, discussion, image, userId, e)}/>,
                                <EditOutlined key="edit" onClick={(e) => ModifModal(id_discussion, title, discussion,e)}/>,
                                <DeleteOutlined key="delete" onClick={() => handleDelete(id_discussion)}/>,
                            ] 
                            : userId === id_users ? 
                                [
                                    <LikeCounter id_discussion={id_discussion}/>,
                                    <Like id_discussion={id_discussion} userId={userId} />,
                                    <CommentOutlined onClick={(e) => showModal(id_discussion, title, discussion, image, userId, e)}/>,
                                    <EditOutlined key="edit" onClick={(e) => ModifModal(id_discussion, title, discussion, e)}/>,
                                ] 
                                :
                                [
                                    <LikeCounter id_discussion={id_discussion}/>,
                                    <Like id_discussion={id_discussion} userId={userId} />,
                                    <CommentOutlined onClick={(e) => showModal(id_discussion, title, discussion, image, userId, e)}/>,
                                ]}
                        cover={ <Media image={image}/>}
                    >
                        <Text style={{whiteSpace: 'pre-line'}}>{discussion}</Text>
                         
                    </Card>
                    
                    <CommentSection id_discussion={id_discussion} id_user={userId} />
                    
                </>
            ))}

            <ModalComment data={selectedDiscussion} visible={isModalVisible} setVisible={setIsModalVisible} />
            <ModalUpdateChat id_discussion={uploadedDiscussionNumber} oldTitle={uploadedTitle} oldDiscussion={uploadedDiscussion} visible={isModalUpdateVisible} setVisible={setIsModalUpdateVisible} />


        </>
    )
}

export default DiscussionCard