import React, {useState, useEffect} from 'react'
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import CustomLayout from '../Components/CustomLayout'
import {Affix, Alert, Button, notification, PageHeader, Space, Table} from 'antd'


interface IUsers {
    id_users: number
    nom: string,
    prenom: string,
    email: string,
    isMedart: boolean,
    iswaitingvalidation: boolean,
    isadmin: boolean
}

const Admin = () => {

    const [users, setUsers] = useState([])
    const [isadmin, setIsAdmin] = useState(false)
    let navigate = useNavigate();

    const getUsers = async () => {
        try {
            const response = await axios.get('https://intranet.med-art.fr/users');
            setUsers(response.data)
        }catch(e) {
            console.log(e)
        }
    }

    const refreshToken = async () => {
        try {
            const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
            const decoded:any = jwt_decode(response.data.accessToken);
            setIsAdmin(decoded.isadmin)
        } catch (e:any) {
            console.log(e)
        }
    }
    
    if (!isadmin) {
        navigate('/', { replace: true })
    }
    
    
    useEffect(() => {
        getUsers();
        refreshToken();
    }, []);

    
        
    const ValidationButton = (id_users:number, validate:boolean) => {
        
        async function validating() {
            try {
                await axios.put(`https://intranet.med-art.fr/validateUser/${id_users}`)
                .then(() =>{
                    window.location.reload();
                });
            } catch (e:any) {
                console.log(e)
            }
        
        }
        
        async function deleting(){
            try {
                await axios.delete(`https://intranet.med-art.fr/deleteUser/${id_users}`)
                .then(() =>{
                    window.location.reload();
                });
            } catch (e:any) {
                console.log(e)
            }
        }
        
        return (
            <>
                {validate ? 
                    <Button type="primary" shape="round" style={{background: '#E57373', borderColor:'#1fe0'}} onClick={validating}>Valider</Button>
                    :
                    <Button type="primary" shape="round" style={{background: '#E57373', borderColor:'#1fe0'}} onClick={deleting}>Supprimer</Button>
                }
            </>
            )
    }
    
    
    const columns = [
        { title: 'Nom', dataIndex: 'nom', key: 'nom',},
        { title: 'Prenom', dataIndex: 'prenom', key: 'prenom',},
        { title: 'Email', dataIndex: 'email', key: 'email',},
        { title: 'Société', dataIndex: 'isMedart', key: 'isMedart',},
        { title: 'Est administrateur', dataIndex: 'isadmin', key: 'isadmin',},
        { title: 'Validation', dataIndex: 'validationButton', key: 'validationButton'}
    ];
    
    const dataSource = users.map((user:IUsers) => (
        {
            id: user.id_users,
            nom: user.nom,
            prenom: user.prenom,
            email: user.email,
            isMedart: user.isMedart ? 'Med-Art' : 'Datamento',
            isadmin: user.isadmin ? 'Oui' : 'Non',
            validationButton: ValidationButton(user.id_users, user.iswaitingvalidation),
        }
    ))
    


    return (
        <CustomLayout>
            
                <PageHeader 
                    title="Liste des utilisateurs"
                    breadcrumb={{
                        routes: [
                            { path: '/', breadcrumbName: 'Accueil'},
                            { path: `/Admin`, breadcrumbName: 'Admin' },
                        ],
                    }}
                    extra={[

                    ]}
                >
                </PageHeader>
            
            <Table dataSource={dataSource} columns={columns} rowKey={"id"} scroll={{ y: '100%' }}/>
        </CustomLayout>
    )
}

export default Admin