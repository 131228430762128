import { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Layout, Image, Input, Form, Button, Radio, message } from 'antd';
import { Header } from 'antd/lib/layout/layout'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

import image from '../Utils/Img/LOGO pour site internet.webp'


const Register = () => {

    const [ nom, setNom ] = useState('');
    const [ prenom, setPrenom ] = useState('');
    const [ email, setEmail] = useState('');
    const [ isMedart, setIsMedart ] = useState('');
    const [ iswaitingvalidation, setIsWaitingValidation] = useState(true)
    const [ userPassword, setUserPassword ]= useState('');
    const [ confPassword, setConfPassword ] = useState('');
    const [ isAdmin, setIsAdmin ] = useState(false)
    let navigate = useNavigate();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        try {
            await axios.post('https://intranet.med-art.fr/register', {
                nom: nom,
                prenom: prenom,
                email: email,
                isMedart: isMedart,
                iswaitingvalidation: iswaitingvalidation,
                userPassword: userPassword,
                confPassword: confPassword,
                isadmin: isAdmin
            }).then(() => {
                navigate('/', { replace: true })
                message.success('Compte créé avec succès, Merci de vous connecter', 10);
            });
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, height: 'auto', width: '100%', background: '#E57373', paddingBottom: 20 }}>
                <Image src={image} alt="logo_Medart" style={{paddingLeft: 20, paddingTop: 20, height: 100}} preview={false}/>        
            </Header>

            <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'50px', minHeight:'100vh'}}>
                <Title>
                        Bienvenue sur l'Intranet Med'Art
                </Title>

                <Title level={4}>
                        Création d'un nouveau profil
                </Title>

                <Form
                    name="normal_login"
                    className="register-form"
                    onFinish={handleSubmit}
                    style={{marginTop: 50}}
                    layout="vertical"
                >
                
                    <Form.Item name="nom" rules={[{ required: true, message: 'Merci de renseigner votre nom !' }]} >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Nom" onChange={e => setNom(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="prenom" rules={[{ required: true, message: 'Merci de renseigner votre prenom !' }]} >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Prénom" onChange={e => setPrenom(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: 'Merci de renseigner votre email !' }]} >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" onChange={e => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item label='Sélectionner votre société:'>
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            onChange={e => setIsMedart(e.target.value)}
                            >
                            <Radio value={true}>Med'Art</Radio>
                            <Radio value={false}>DataMento</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="userPassword" rules={[{ required: true, message: 'Merci de renseigner votre mot de passe !' }]} >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Mot de Passe" type="password" onChange={e => setUserPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="confPassword" rules={[{ required: true, message: 'Merci de confirmer votre mot de passe !' }]} >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirmation Mot de Passe" type="password" onChange={e => setConfPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleSubmit} shape="round" style={{background: '#E57373', borderColor:'#1fe0', marginRight: 20}}>Créer un compte</Button>
                        <a href="/login">Retour</a>
                    </Form.Item>
                </Form>             
            </Layout>
        </Layout>   

    )

}

export default Register