import React, {useState, useEffect} from 'react'
import CustomLayout from '../Components/CustomLayout';
import Title from 'antd/lib/typography/Title';
import { Layout, Breadcrumb } from 'antd';




const Histoire = () => {
  






    return (
            <CustomLayout>
                <Breadcrumb style={{padding: 10}}>
                    <Breadcrumb.Item>
                        <a href="/">Accueil</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Notre Histoire
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'200px', backgroundColor:'#FFFFFF'}}>
                    <Title>
                        Notre Histoire
                    </Title>
                </Layout>
            </CustomLayout>
    )
}

export default Histoire
