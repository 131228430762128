import {useState, useEffect} from 'react'
import CustomLayout from '../Components/CustomLayout';
import axios, { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";
import { Layout, Affix, Card, Button, Modal, Input, PageHeader, message, Upload, DatePicker, Switch, Form, Image, Typography} from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';


const { Text } = Typography;


interface INews {
    id:number,
    title:string,
    text:string,
    image:string,
    date:Date,
    startDate:Date,
    endDate:Date
}

const { TextArea } = Input;





const News = () => {
    
    // Récupérer le role de l'utilisateur
    
    const [isadmin, setIsAdmin] = useState(false)
    
    
    const admin = async () => {
        const response:AxiosResponse = await axios.get('https://intranet.med-art.fr/token');
        const decoded:any = jwt_decode(response.data.accessToken);
        setIsAdmin(decoded.isadmin);
        
    }
        
    useEffect(() => {
        getNewsData()
        getNewsDataWithOutDate()
        admin();
    }, []);
    
    // Methode GET news data----------------------------------------------------------
    
    const [newsData, setNewsData] = useState<INews[]>([]);
    const [newsDataWithOutDate, setNewsDataWithOutDate] = useState<INews[]>([]);
    const [archivedNews, setArchivedNews] = useState(true)
    

    const handleSwitch = () => {
        setArchivedNews(!archivedNews)
        console.log(newsDataWithOutDate)
    }
    
    async function getNewsData() {
        const result = await fetch('https://intranet.med-art.fr/newsDate');
        const getResults = await result.json();
        setNewsData(getResults);
    }

    async function getNewsDataWithOutDate() {
        const result = await fetch('https://intranet.med-art.fr/news');
        const getResults = await result.json();
        setNewsDataWithOutDate(getResults);
    }




        
    // Gestion de la modal ----------------------------------------------------------
    
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [image, setImage ]= useState('')
    const [startDate, setStartDate] = useState(Date)
    const [endDate, setEndDate] = useState(Date)
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const onChangeStartDate: DatePickerProps['onChange'] = (date, startdateString) => {
        setStartDate(startdateString);
    };

    const onChangeEndDate: DatePickerProps['onChange'] = (date, enddateString) => {
        setEndDate(enddateString); 
    };

    const onUpload = (value: any) => {
        const imageName = value.file.name
        setImage(imageName)
    }
    
    const showModal = () => {
        setIsModalVisible(true);
    };
    
    

    async function handleOk (e: { preventDefault: () => void; }) {   
        
        const postNews = {
            title,
            text,
            image,
            startDate,
            endDate
        };

        await fetch('https://intranet.med-art.fr/news', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postNews),
        }).then(() => {
            message.success('Nouvelle actualité ajouté', 10);
            setIsModalVisible(false);
            window.location.reload(); 
        });
    };
    
    
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModifModalVisible(false);
    };  
    
    // Effacer une News 
    
    async function handleDelete (id:number) {
        
        await fetch(`https://intranet.med-art.fr/news/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json'}
        }).then(() => {
            message.success('Actualité supprimé', 10);
            window.location.reload(); 
        })
        
    };
    
    // Modifier une News
    
    const [isModifModalVisible, setIsModifModalVisible] = useState(false);
    const [ModifID, setModifID] = useState(0)
    const [ModifTitle, setModifTitle] = useState('')
    const [ModifText, setModifText] = useState('')
    
    
    function ModifModal(id:number, title:string, text:string) {
        setIsModifModalVisible(true)
        setModifTitle(title)
        setModifText(text)
        setModifID(id)
    }
    
    async function handleChange() {

        let updateData = {
            "id" : ModifID,
            "title" : ModifTitle,
            "text" : ModifText
        }
        
        
        await fetch(`https://intranet.med-art.fr/news/${ModifID}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(updateData)
        }).then(() => {
            message.success('Actualité modifiée', 10)
            setIsModifModalVisible(false);
            window.location.reload(); 
        })
    }
    

    return (
        <CustomLayout>
            <Affix offsetTop={210}>
                <PageHeader 
                    title="Actualités"
                    breadcrumb={{
                        routes: [
                            { path: '/', breadcrumbName: 'Accueil'},
                            { path: '/Actualités', breadcrumbName: 'Actualités' },
                    ],
                }}
                    extra={[
                        isadmin ? <Button onClick={showModal}>Créer un nouvel article</Button> : null,
                        <Switch checkedChildren="Cacher les actus archivées" unCheckedChildren="Afficher les actus archivées" onChange={handleSwitch}/>
                        
                    ]}
                >
                </PageHeader>
            </Affix>
                <Layout style={{display: 'flex', justifyContent:'center', alignItems:'center', paddingTop:'10px', backgroundColor:'#FFFFFF'}}>
                    {archivedNews ? 
                    <>
                    {newsData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map(({id, title, text, image, date}:INews) => (
                        <Card
                            hoverable
                            key={id}
                            title={title}
                            extra={<>Publié le {new Date(date).toLocaleDateString("fr")}</>}
                            style={{ width: '50%', maxWidth: '50%', margin: 10 }}
                            actions={[
                                isadmin ? <EditOutlined key="edit" onClick={() => ModifModal(id, title, text)}/> : null,
                                isadmin ? <DeleteOutlined key="delete" onClick={() => handleDelete(id)}/> : null,
                            ]}
                            cover={
                                <Image
                                    alt="medart"
                                    src= { image ? require(`/src/Utils/NewsImg/${image}`) : require('../Utils/Img/LOGO pour site internet.webp')}
                                    preview={false}
                                    style={{padding: 10, maxWidth:300, maxHeight:300}}
                                />
                            }
                        >
                            <Text style={{whiteSpace: 'pre-line'}}>{text}</Text>
                        </Card>
                    ))}    
                    </>
                    :
                    <>
                    {newsDataWithOutDate.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map(({id, title, text, image, date}:INews) => (
                        <Card
                            hoverable
                            key={id}
                            title={title}
                            extra={<>Publié le {new Date(date).toLocaleDateString("fr")}</>}
                            style={{ width: '50%', maxWidth: '50%', margin: 10 }}
                            actions={[
                                isadmin ? <EditOutlined key="edit" onClick={() => ModifModal(id, title, text)}/> : null,
                                isadmin ? <DeleteOutlined key="delete" onClick={() => handleDelete(id)}/> : null,
                            ]}
                            cover={
                                <Image
                                    alt="medart"
                                    src= { image ? require(`/src/Utils/NewsImg/${image}`) : require('../Utils/Img/LOGO pour site internet.webp')}
                                    preview={false}
                                    style={{padding: 10}}
                                />
                            }
                        >
                            <Text style={{whiteSpace: 'pre-line'}}>{text}</Text> 
                        </Card>
                    ))}    
                    </>
                    }

                    <>
                        <Modal 
                            title="Créer une nouvelle actualité" 
                            visible={isModalVisible}
                            footer={null}
                            onCancel={handleCancel}
                            >
                                <Form
                                    onFinish={handleOk}
                                >
                                    <Form.Item name="title" rules={[{ required: true, message: 'Merci de renseigner votre titre !' }]}>
                                        <Input placeholder="Ajouter un titre" onChange={(e) => setTitle(e.target.value)}/>
                                    </Form.Item>
                                    <Form.Item name="text" rules={[{ required: true, message: 'Merci de renseigner votre texte !' }]}>
                                        <TextArea rows={4} placeholder="Taper votre actualité" onChange={(e) => setText(e.target.value)}/>
                                    </Form.Item>
                                    <Form.Item name="image" >
                                        <Upload name="image" action={'https://intranet.med-art.fr/image'} onChange={onUpload} >
                                            <Button icon={<UploadOutlined />}>Charger une image</Button>
                                        </Upload>    
                                    </Form.Item>
                                    <Form.Item name="startDate" label="Date de début de l'actualité" rules={[{ required: true, message: 'Merci de renseigner une date !' }]}>
                                        <DatePicker onChange={onChangeStartDate} />
                                    </Form.Item>
                                    <Form.Item name="endDate" label="Date de fin de l'actualité" rules={[{ required: true, message: 'Merci de renseigner une date !' }]}>
                                        <DatePicker onChange={onChangeEndDate} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button key="submit" type="primary" htmlType='submit' style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                                            Valider
                                        </Button>
                                        <Button key="cancel" onClick={handleCancel}>
                                            Annuler
                                        </Button>
                                    </Form.Item>
                                </Form>
                        </Modal>
                    </>

                    <>
                        <Modal 
                            title="Modifier une nouvelle actualité" 
                            visible={isModifModalVisible} 
                            onOk={handleChange} 
                            onCancel={handleCancel}
                            footer={[
                                <Button key="cancel" onClick={handleCancel}>
                                    Annuler
                                </Button>,
                                <Button key="submit" type="primary" onClick={handleChange} style={{marginRight: 10, background: '#E57373', borderColor:'#1fe0'}}>
                                    Valider
                                </Button>,
                                ]}
                            >
                            <Input defaultValue={ModifTitle} style={{marginBottom: 10}} onChange={(e) => setModifTitle(e.target.value)} />
                            <TextArea defaultValue={ModifText} rows={4} onChange={(e) => setModifText(e.target.value)}/>
                        </Modal>
                    </>              
                </Layout>
            </CustomLayout>
    )
}

export default News
